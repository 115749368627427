
import React,{ useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../firebase'
import BlogCards01 from '../../Components/BlogCards/BlogCards01';
import LatestNews from '../../Components/LatestNews';

 const Blog =  () => {
    const BlogDataHome01 = [
        {
            id: '1',
            img: 'images/blog/b6.png',
            blogClass: 'col-lg-4 col-md-6',
            title: 'Diversity in Building Celebrated by Builderrine',
            description: 'Builderrine will connect with 10000 people from 90 companies who work on its’ projects each day...'
        },
        {
            id: '2',
            img: 'images/blog/b7.png',
            blogClass: 'col-lg-4 col-md-6',
            title: 'Company Receives Recognition for Excellence',
            description: 'The construction industry has the capacity to absorb more people into the workforce...'
        },

    ];

    const [listings, setListings] = useState(null)
    useEffect(() => {
        async function fetchUserListings() {
          const listingRef = collection(db, "blogs")
          const q = query(listingRef)
          const querySnap = await getDocs(q)
          let listings = []
          querySnap.forEach((doc)=> {
            return listings.push({
            id: doc.id,
            data: doc.data(),
          })})
          setListings(listings)
        //   setLoading(false)
        }
        fetchUserListings()
        },[])
    // const params = useParams()
    // const [customers, setCustomers] = useState([])
    // const [loading, setLoading] = useState(true)
    // useEffect(() => {
    //   async function fetchBlogs() {
    //     const response=db.collection('customers');
    //     const data=await response.getDoc();
    //     data.docs.forEach(item=>{
    //         setCustomers([...customers,item.data()])})
    //   }
    //   }, [customers])

    //   const fetchBlogs=async()=>{
    //     const response=db.collection('customers');
    //     const data=await response.get();
    //     data.docs.forEach(item=>{
    //         setCustomers([...customers,item.data()])
    //     })
    // }
    return (
        <div className="blog section">
            <div className="container">
                <div className="blog_grid">
                    <div className="row">
                        <LatestNews />                           
                        {
                            listings &&
                            listings.map(data => <BlogCards01
                                key={data.id}
                                data={data.data}
                            />)
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Blog