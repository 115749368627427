// import { RouterProvider } from 'react-router-dom';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './assets/css/style.css';
import ThemeSwitcher from './Components/common/ThemeSwitcher';
import { routes } from './Routes/Routes';
import { ToastContainer } from 'react-toastify';
import Home01 from "./Pages/Homes/Home01";
import Header from "./Components/common/Header";
import Footer from "./Components/common/Footer";
import About from "./Pages/About";
import Projects01 from "./Pages/Projects/Projects01";
import ProjectDetails from "./Pages/Projects/ProjectDetails";
import Service02 from "./Containers/ServiceContainers/Service02";
import ServiceDetails from "./Pages/Services/ServiceDetails";
import Team from "./Containers/Team";
import FAQ from "./Pages/FAQ";
import ContactPage from "./Pages/ContactPage";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import Blog from "./Containers/Blogs/Blog";
import BlogDetails from "./Pages/Blogs/BlogDetails";
import LandingPage from "./Pages/Homes/LandingPage";

function App() {
  return (
    <div>
      {/* <Loader/> */}
      <ThemeSwitcher/>
      <Router>
        <Header />
        <Routes>
          <Route path="/"   element={<LandingPage />} />       
          <Route path="/home"   element={<Home01/>} />       
          <Route path="/project"   element={<Projects01/>} />       
          <Route path="/project-details"   element={<ProjectDetails/>} />       
          <Route path="/services"   element={<Service02/>} />       
          <Route path="/service-details"   element={<ServiceDetails/>} />       
          <Route path="/about"   element={<About/>} />       
          <Route path="/team"   element={<Team/>} />       
          <Route path="/faq"   element={<FAQ/>} />       
          <Route path="/contact"   element={<ContactPage/>} />       
          <Route path="/blog"   element={<Blog/>} />       
          <Route path="/blog-details"   element={<BlogDetails/>} />       
          <Route path="*"   element={<ErrorPage/>} />       
        </Routes>
        <Footer />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;

// function App() {
//   return (
//     <div>
//       {/* <Loader/> */}
//       <ThemeSwitcher/>
//       <RouterProvider router={routes} />
//       <ToastContainer />
//     </div>
//   );
// }

// export default App;
