import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../firebase'

import ServiceCard01 from '../../Components/ServiceCards/ServiceCard01';
import AboutBelowContent from '../../Components/ExperienceBlocks/AboutBelowContent';
import FunBox01 from '../../Components/FunFactElements/FunBox01';
import ProjectHome01 from '../../Containers/ProjectContainers/ProjectHome01'
import FAQ from '../FAQ'
import ContactPage from '../ContactPage'
import ContactHome02 from '../../Containers/Contacts/ContactHome02'


export default function LandingPage() {

    const params = useParams()
    const [listing, setListing] = useState(null)
    const [loading, setLoading] = useState(true)
    // const code = "QzPgejSPhFQBKpIDApDN"
    const code = process.env.REACT_APP_USER_GC
    
    useEffect(() => {
        async function fetchListing() {
            const docRef = doc(db, "contents",code)
            const docSnap = await getDoc(docRef)
            if(docSnap.exists()){
                setListing(docSnap.data())
                setLoading(false)
            }
        }
        fetchListing()
        // console.log(listing)
    },[])

    const ServiceData = [
        {
            id: '1',
            img: 'images/services/s1.png',
            img02: 'images/services/ss1.png',
            number: 'ONE',
            bg: 'bg_1',
            title: 'Industrial & Highrise Construction'
        },
        {
            id: '2',
            img: 'images/services/s2.png',
            img02: 'images/services/ss2.png',
            number: 'TWO',
            bg: 'bg_2',
            title: 'Real Estate & Housing Construction'
        },
        {
            id: '3',
            img: 'images/services/s3.png',
            img02: 'images/services/sbg3.png',
            number: 'THREE',
            bg: 'bg_3',
            title: 'Commertial Design & Building Construction'
        }
    ];
    //experience
    const headerData = {
        img: 'images/about/shadow_icon1.png',
        heading: 'Building A New Era in world of Construction',
        subheading: 'ABOUT BUILDERRINE CONSTRUCTION',
        description: "Builderrine is the safe, reliable and cost effective construction company. We offer best construction Services. We have more than 35 years of experience in the field of building & construction. If you want to build any highrise or housing projects, you are in the best palce right now"
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Most Reliable',
            description: 'More than 200 Company trusted us'

        },
        {
            id: '2',
            title: 'Cost Effective',
            description: 'Builderrine is famous for its cost effectiveness'
        },
    ];

    //FunFact
    const FunData = [
        {
            id: '1',
            img: 'images/funfact/p1.png',
            number: '33',
            name: 'Years of Experience'
        },
        {
            id: '2',
            img: 'images/funfact/p2.png',
            number: '100',
            name: 'Projects Completed'
        },
        {
            id: '3',
            img: 'images/funfact/p3.png',
            number: '300',
            name: 'Expert Builders'
        },
        {
            id: '4',
            img: 'images/funfact/p4.png',
            number: '36',
            name: 'Ongoing Project'
        },
    ];

  return (
    
        <>
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content">
                                <h5 className="sub_heading">CALL US ANYTIME<span>{listing ? listing.slider.phone : ""} </span></h5>
                                <h2 className="heading">{listing ? listing.slider.title : ""}</h2>
                                <p className="desc">{listing ? listing.slider.phone : ""}</p>
                                <div className="slider_button">
                                    <a href="/about" className="button">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 d-flex align-items-end">
                            <div className="layer_object">
                                <img src="images/slider/layer_2.png" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

        <div className="service">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    {
                        listing &&
                        listing.serviceData.map(data =>
                            <ServiceCard01
                                key={data.title}
                                data={data}
                            />
                        )
                    }
                    {/* {
                        ServiceData.map(data =>
                            <ServiceCard01
                                key={data.id}
                                data={data}
                            />
                        )
                    } */}
                </div>
            </div>
        </div>

        <div className="experience section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>35</h1>
                                    Years of Experience
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="images/about/11.png" alt="" />
                                <div className="object">
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/s1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                <div className="shadow_icon"><img src={listing ? listing.headerData.img : ""} alt="" /></div>
                                <h6 className="section_sub_title">{listing ? listing.headerData.subheading : ""}</h6>
                                <h1 className="section_title">{listing ? listing.headerData.heading : ""}</h1>
                                <p className="section_desc">{listing ? listing.headerData.description : ""}</p>
                                <div className="about_below">
                                    {
                                        listing &&
                                        listing.ExpericenData.map(data =>
                                            <AboutBelowContent
                                                key={data.title}
                                                data={data}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <a className="button" href="/about">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="funfacts" className="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="section_header">
                            <h6 className="section_sub_title">FUNFACTS OF BUILDERRINE CONSTRUCTION</h6>
                            <h1 className="section_title">Our Fact Speaks about the result of our Effort</h1>
                        </div>
                        <div className="fun_bottom">
                            <div className="row">
                                {
                                    listing &&
                                    listing.FunData.map(data =>
                                        <FunBox01
                                            key={data.name}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="man_img">
                            <img src="images/funfact/img_fun.png" alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ProjectHome01 /> 
        <FAQ />
        <ContactHome02 />
        </>
    
  )
}
